import planActions from "@/calendesk/mixins/planActions";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import api from "@/lib/calendesk-js-library/api/api";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { mapActions, mapGetters } from "vuex";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";

export default {
  mixins: [planActions],
  computed: {
    ...mapGetters({
      shouldRefreshMobileDrafts: "mobileEditor/shouldRefreshMobileDrafts",
      getMobileDraft: "mobileEditor/getDraft",
    }),
  },
  methods: {
    ...mapActions({
      updateConfigurationInServer: "setup/updateConfiguration",
      refreshMobileDrafts: "mobileEditor/refreshMobileDrafts",
      updateDraft: "mobileEditor/updateDraft",
      fetchMobileDrafts: "mobileEditor/fetchAll",
      fetchSettings: "setup/fetchSettings",
    }),
    handleDisableDraftClick(draft) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans(
          "plugins_mobile_drafts_disable_confirm_dialog_title"
        ),
        warning: this.$trans(
          "plugins_mobile_drafts_disable_confirm_dialog_warning"
        ),
        importantInformation: this.$trans(
          "plugins_mobile_drafts_disable_confirm_dialog_important_information"
        ),
        confirmationText: this.$trans("disable"),
        confirmAction: () => {
          this.disableDraft(draft);
        },
      });
    },
    disableDraft(draft) {
      pushEvent("disableMobileDraft");

      this.setCommonDialogLoader(true);
      api
        .disableMobileDraft(draft.id)
        .then(() => {
          this.fetchSettings().then(() => {
            this.refreshMobileDrafts().then(() => {
              this.closeConfirmDialog();
              this.setCommonDialogLoader(false);
            });
          });
        })
        .catch((error) => {
          this.setCommonDialogLoader(false);
          this.closeConfirmDialog();
          errorNotification(null, error);
        });
    },
    handleChangeDraftNameClick(draft) {
      this.openDialog({
        type: dialogTypes.CHANGE_DRAFT_NAME,
        size: dialogSize.SMALL,
        title: this.$trans("update_draft_name_title"),
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
        data: {
          id: draft.id,
          description: draft.description,
          type: this.$helpers.draftType.MOBILE,
        },
      });
    },
    handlePublishDraftClick(draft) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        importantInformation: "",
        confirmationText: this.$trans("ok"),
        confirmAction: () => {
          this.publishDraft(draft);
        },
      });
    },
    publishDraft(draft) {
      this.setCommonDialogLoader(true);

      pushEvent("publishMobileDraft");

      api
        .publishMobileDraft(draft.id)
        .then(() => {
          this.fetchSettings().then(() => {
            this.refreshMobileDrafts().then(() => {
              this.closeConfirmDialog();
              this.setCommonDialogLoader(false);
            });
          });
        })
        .catch((error) => {
          this.closeConfirmDialog();
          this.setCommonDialogLoader(false);
          errorNotification(null, error);
        });
    },
    handleAddNewMobileClick() {
      if (this.canCreateMobileAppForCustomers) {
        this.openDialog({
          type: dialogTypes.CREATE_MOBILE_DRAFT_DIALOG,
          title: this.$trans("create_mobile_draft_dialog_title"),
          size: dialogSize.LARGE,
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleEditMobileAppClick(draft) {
      this.updateDraft(draft);
      this.openMobileBuilder();
    },
    openMobileBuilder() {
      pushEvent("openMobileBuilder");

      this.$router.push({
        name: "plugins_mobile_editor",
        params: { id: this.getMobileDraft.id },
      });
    },
    handleDeleteDraftsClick(drafts) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("plugins_mobile_drafts_remove_confirm_dialog_title"),
        warning: this.$trans(
          "plugins_mobile_drafts_remove_confirm_dialog_warning"
        ),
        confirmationText: this.$trans("remove"),
        confirmAction: () => {
          this.removeDrafts(drafts);
        },
      });
    },
    removeDrafts(drafts) {
      this.setCommonDialogLoader(true);

      const promises = drafts.map((draft) => api.removeMobileDraft(draft.id));

      Promise.all(promises)
        .then(() => {
          this.refreshMobileDrafts();
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.setCommonDialogLoader(false);
          this.closeConfirmDialog();
        });
    },
  },
};
